import { library, dom } from '@fortawesome/fontawesome-svg-core';

import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
	faBars,
	faPhone,
	faFax,
	faEnvelope,
	faGlobe
} from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faTriangle } from '@fortawesome/pro-solid-svg-icons';


library.add(
	faBars,
	faFacebookF,
	faInstagram,
	faTriangle,
	faArrowRight,
	faPhone,
	faFax,
	faEnvelope,
	faGlobe
);

dom.watch();
